import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Input, Button, Select } from "antd";
import logoSeller from "../../../../images/box.png";
import logoAdmin from "../../../../images/box.png";
import logoAdminAr from "../../../../images/box.png";
import logoSellerAr from "../../../../images/box.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../style-sheets/login.css";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../../../components/contexts/LanguageContext";
import useLanguage from "../../../../hooks/useLanguage";
import ModifiedForm from "../../../../components/form/ModifiedForm";
import { TextField } from "../../../../components/form/TextInputFields";
import SelectSearch from "../../../../components/select-search/SelectSearch";
import {
    AccountConfirmationModal,
    AccountRegisterationSuccessModel,
    CreationConfirmationModal,
    UnSuccessfulRequest,
} from "../../../../components/modals/confirmationModal";
import { SHOW_ACCOUNT_CREATION_ERROR } from "../../../../global-state/actions/actionTypes";
import axiosInstance from "../../../../api/axios";
import { addAccount } from "../../../../global-state/actions/addAccountAction";
import { Form } from "react-bootstrap";
import MerchantRegistrationRepository from "../data/registration-repository";
import useQuery from "../../../../hooks/useQuery";
import SelectAreas from "../../../../components/select-search/AreasSelect";
import { Modal } from "antd";

const RegisterInfo2 = ({ phone,flag, ...props }) => {
    const value = 1;

    const history = useHistory();

    const account = useSelector((state) => state.account);
    const errors = useSelector((state) => state.account.errors) ?? "";
    const dispatch = useDispatch();
    const [workModels, setWorkModeals] = useState([]);
    const [next, SetNext] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [workModelAreas, setWorkModelAreas] = useState([]);
    const [cities, setCities] = useState([]);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedZone, setSelectedZone] = useState([]);
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [ID, setID] = useState("");
    const [addressErr, setAddressErr] = useState("");
    const [password, setPassword] = useState("");
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [addresses, setAddresses] = useState([
        {
            address: "",
            branch: 1,
            //   city: "",
            area: "",
            new_work_model: "",
        },
    ]);
    const { t, i18n } = useTranslation();
    console.log({ account });
    const [uniqueNumberError, setUniqueNumberError] = useState(false);
    const [taxRegistrationNumber, setTaxRegistrationNumber] = useState("");
    const [taxRegistrationNumberError, setTaxRegistrationNumberError] = useState("");
    // const [flag, setFlag] = useState(1); // Default to Company

    const getErrors = (e) => {
        console.log({ e });

        setUniqueNumberError(e.user);

        dispatch({
            type: SHOW_ACCOUNT_CREATION_ERROR,
            payload: e,
        });
    };
    const showModal = () => {
        setShow(true);
    };

    const showConfirmModal = () => {
        setShowConfirm(true);
    };

    const hide = () => {
        setShow(false);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        history.push("/login");
    };

    const handleInput = (e, index) => {
        const { name, value } = e.target;
        const list = [...addresses];
        list[index][name] = value;
        const addressesList = [...selectedAddress];
        addressesList[index] = e.target.value;
        setSelectedAddress(addressesList);
        setAddresses(list);
    };

    const handleSelectedZone = (e, i) => {
        const list = [...addresses];
        list[i].city = e;
        const copy = [...selectedZone];
        copy[i] = e;
        setSelectedZone(copy);
    };

    const handleSelectedArea = (e, i) => {
        const list = [...addresses];
        list[i].area = e;
        const copy = [...selectedArea];
        copy[i] = e;
        setSelectedArea(copy);
        setAddresses(list);
    };

    const handleSelectedBranch = (e, i) => {
        const list = [...addresses];
        list[i].branch = e;
        const copy = [...selectedBranch];
        copy[i] = e;
        console.log(e);
        console.log(copy);
        setSelectedBranch(copy);
        setAddresses(list);
    };

    useEffect(() => {
        console.log(selectedBranch);
    }, [selectedBranch]);
    const addAnotherAddress = () => {
        const addressList = [...addresses];
        setAddresses([
            ...addressList,
            {
                address: "",
                branch: "1",
                // city: "",
                area: "",
                new_work_model: "",
            },
        ]);
    };
    const getAreas = async (workModelId, i) => {
        console.log({
            workModelId,
            workModels,
            i,
            workModelsI: workModels[i],
            new_work_model: workModels[i]?.find((it) => it.id == workModelId),
        });
        let result = await axiosInstance
            .get(`/FilterAreas/`, {
                params: {
                    page_size: 100000,
                },
            })
            .catch((err) => console.log(err));

        setAreas(result?.data?.data);
    };
    const { uid64, token } = useQuery().query;

    const [isChecked, setIsChecked] = useState(false); // Checkbox state
    const [checkboxError, setCheckboxError] = useState(""); // Error state for checkbox

    const validate = (data) => {
        if (!isChecked) {
            setCheckboxError("برجاء الموافقة على الشروط و الاحكام");
            return; // Prevent submission if not checked
        }
        setCheckboxError("");
        console.log(`Form Data = ${data}`);
        // Validate tax registration number
        if (!taxRegistrationNumberError || taxRegistrationNumber === "") {
            data.tax_registration_number = taxRegistrationNumber;
        } else {
            return; // Do not proceed with the request
        }
        if (
            value === 1 &&
            (selectedArea.length === 0 || selectedAddress.length === 0)
        ) {
            setAddressErr(t("addressErr"));
        } else {
            setAddressErr("");
            console.log(addresses);
            data.seller_addresses = addresses;
            data.token = token;
            data.uid64 = uid64;
            data.type = -1;
            data.seller_type = flag;
            data.email = data.email.toLowerCase();
            data.phone = phone ?? localStorage.getItem("registerPhone");
            data.fullPhone = true;
            data.password_2 = data.password;
            dispatch(addAccount(data));
            dispatch({ type: SHOW_ACCOUNT_CREATION_ERROR, payload: "" });
            setShow(true);
        }

        // handleChange(null, value + 1)
    };

    const getWorkModels = async (selectedBranch) => {
        const result = await axiosInstance
            .get(`/NewWorkModel/?branch=${selectedBranch}&page_size=10000`)
            .catch((err) => console.log(err));

        return result.data.data;
    };

    //   useEffect(() => {
    //     // setWorkModeals([]);
    //     getWorkModels();
    //     getAreas();
    //     // return () => setWorkModeals([]);
    //   }, [selectedBranch]);
    const getBranches = async (e, i) => {
        const result = await axiosInstance
            .get("/Branch/?page_size=100000")
            .catch((err) => console.log(err));
        if (result) {
            setBranches(result.data.data);
            SetNext(result.data.next);
        }
    };

    useEffect(() => {
        getBranches();
        getAreas();
        console.log(account);
        console.log('Flag value is ' , flag);
        
    }, []);

    const getCities = async (e, i) => {
        console.log(i);
        console.log(selectedBranch[i]);
        const result = await axiosInstance
            .get("/City/?page_size=100000")
            .catch((err) => console.log(err));
        if (result) {
            setCities(result.data.data);
            SetNext(result.data.next);
        }
    };

    // const getAreas = async (e, i) => {
    //     console.log(i);
    //     console.log(selectedZone[i]);
    //     const result = await axiosInstance
    //         .get(
    //             `/FilterAreas/?page_size=100000&city=${
    //                 branches.find((b) => b.id === selectedBranch[i]).city
    //             }`
    //         )
    //         .catch((err) => console.log(err));
    //     if (result) {
    //         setAreas(result.data.data);
    //         SetNext(result.data.next);
    //     }
    // };

    const onScroll = async (event, link) => {
        const target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${next}`)
                .catch((err) => console.log(err));

            if (result) {
                setWorkModeals([...workModels, ...result.data.data]);
                SetNext(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    const deleteAddress = (e, i) => {
        const list = [...addresses];
        const branches = [...selectedBranch];
        const zones = [...selectedZone];
        const areas = [...selectedArea];

        branches.splice(i, 1);
        zones.splice(i, 1);
        areas.splice(i, 1);
        list.splice(i, 1);

        setSelectedBranch(branches);
        setSelectedZone(zones);
        setSelectedArea(areas);
        setAddresses(list);
    };

    const lang = useLanguage();

    const languageChecker = (englishCallback, arabicCallback) =>
        lang.includes("en") ? englishCallback : arabicCallback;
    console.log({ directionLOG: languageChecker("ltr", "rtl"), lang });

    function formatPasswordErrors(error) {
        const errorsArr = error.slice(1, error.length - 1).split(", ");

        return errorsArr.map((it) => <div>{it.slice(1, it.length - 1)}</div>);
    }

    const handleTaxRegistrationNumberChange = (e) => {
        const value = e.target.value;
        setTaxRegistrationNumber(value);
    
        // Perform validation only if the value is non-empty
        if (value !== "") {
            // Perform validation
            const regex = /^3\d{13}3$/;
            if (value.length === 15 && regex.test(value)) {
                setTaxRegistrationNumberError("");
            } else {
                setTaxRegistrationNumberError("Tax registration number must be 15 digits and start and end with 3.");
            }
        } else {
            // Clear the error if the value is empty
            setTaxRegistrationNumberError("");
        }
    };
    const hideAndDisable = flag === 2;

    return (
        <div>
            <div className="mt-4">
                {/*<h1>{t("register")}</h1>*/}
                <ModifiedForm
                    submitFunction={validate}
                    defaultValues={account ?? undefined}
                >
                    <fieldset disabled={value === 2}>
                        
                            
                                <div className="text-area-form">
                                    <TextField
                                        id="name"
                                        className="formTextField text-area"
                                        title={t("fullname")+ "*"}
                                        disabled={false}
                                        name="name"
                                        validation={{ required: true }}
                                        shouldRegister
                                    />

                                    <TextField
                                        id="email"
                                        className="formTextField text-area"
                                        title={t("email")+ "*"}
                                        shouldRegister
                                        backendErrors={
                                            errors !== ""
                                                ? errors.email?.length > 0
                                                    ? errors?.email[0]
                                                    : null
                                                : null
                                        }
                                        validation={{ required: true }}
                                        name="email"
                                        disabled={false}
                                    />
                                    {/* <TextField
                                        id="tax_registration_number"
                                        className="formTextField text-area"
                                        title={t("tax_number")}
                                        shouldRegister
                                        name="tax_registration_number"
                                        value={taxRegistrationNumber}
                                        onChange={(e) => handleTaxRegistrationNumberChange(e)}
                                        disabled={false}
                                    />
                                    {taxRegistrationNumberError && <div className="error-message" style={{"color":"red"}}>{taxRegistrationNumberError}</div>} */}
                                 {hideAndDisable ? null : (
                                    <TextField
                                        id="commercial_account"
                                        className="formTextField text-area"
                                        title={t("commercial_account")}
                                        shouldRegister
                                        name="commercial_account"
                                        note={t(
                                            "commercial_account_placeholder"
                                        )}
                                        disabled={false}
                                    />
                                 )}
                                </div>
                            
                            
                                <div className="text-area-form">
                                {hideAndDisable ? null : (
                                    <TextField
                                        id="store_name"
                                        className="formTextField text-area"
                                        title={t("storename")+ "*"}
                                        shouldRegister
                                        validation={{ required: true }}
                                        name="store_name"
                                        disabled={false}
                                        backendErrors={
                                            errors !== ""
                                                ? errors.store_name?.length > 0
                                                    ? errors?.store_name[0]
                                                    : null
                                                : null
                                        }
                                    />
                                )}
                                    <TextField
                                            id="marketer_invitation_code"
                                            className="formTextField text-area"
                                            title={t("invitaioncode")} 
                                            backendErrors={
                                                errors !== ""
                                                    ? errors.marketer_invitation_code?.length > 0
                                                        ? errors?.marketer_invitation_code[0]
                                                        : null
                                                    : null
                                            }
                                            shouldRegister
                                            name="marketer_invitation_code"
                                            disabled={false}
                                            // Add validation rules if needed
                                        />

                                    <TextField
                                        id="password"
                                        backendErrors={
                                            errors !== ""
                                                ? errors.password?.length > 0
                                                    ? formatPasswordErrors(
                                                          errors?.password[0]
                                                      )
                                                    : null
                                                : null
                                        }
                                        className="formTextField text-area"
                                        title={t("password")+ "*"}
                                        shouldRegister
                                        name="password"
                                        validation={{ required: true }}
                                        disabled={false}
                                        type={"password"}
                                    />
                                </div>

                                {/* <div className="text-area-form">
                                    <label htmlFor="flag">{t("flag")}</label>
                                    <select
                                        id="flag"
                                        value={flag}
                                        onChange={(e) => setFlag(parseInt(e.target.value))}
                                    >
                                        <option value={1}>Company</option>
                                        <option value={2}>Individual</option>
                                    </select>
                                </div> */}
                        
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        ></div>
                        {value !== 2 &&
                            addresses.map((address, i) => {
                                return (
                                    <div key={i} className="address-container">
                                        
                                                <div className="text-area-form">
                                                    {/*<label>*/}
                                                    {/*    {t("sideMenu.branches")}*/}
                                                    {/*</label>*/}

                                                    {/*<Select*/}
                                                    {/*    // onSearch={onSearch}*/}
                                                    {/*    // filterOption={false}*/}
                                                    {/*    // showSearch*/}
                                                    {/*    value={*/}
                                                    {/*        selectedBranch[i]*/}
                                                    {/*    }*/}
                                                    {/*    onChange={(e) =>*/}
                                                    {/*        handleSelectedBranch(*/}
                                                    {/*            e,*/}
                                                    {/*            i*/}
                                                    {/*        )*/}
                                                    {/*    }*/}
                                                    {/*    disabled={value === 2}*/}
                                                    {/*    allowClear*/}
                                                    {/*    // ref={ref}*/}
                                                    {/*    // onPopupScroll={onScroll}*/}
                                                    {/*    className="form-control paginated"*/}
                                                    {/*    name="zone"*/}
                                                    {/*>*/}
                                                    {/*    {branches.map(*/}
                                                    {/*        (branch) => (*/}
                                                    {/*            <Select.Option*/}
                                                    {/*                key={*/}
                                                    {/*                    branch.id*/}
                                                    {/*                }*/}
                                                    {/*                name="branch"*/}
                                                    {/*                value={*/}
                                                    {/*                    branch.id*/}
                                                    {/*                }*/}
                                                    {/*            >*/}
                                                    {/*                {*/}
                                                    {/*                    branch.name*/}
                                                    {/*                }*/}
                                                    {/*            </Select.Option>*/}
                                                    {/*        )*/}
                                                    {/*    )}*/}
                                                    {/*</Select>*/}

                                                    {/* <label>{t("zones")}</label>

                                                <Select
                                                    // onSearch={onSearch}
                                                    // filterOption={false}
                                                    // showSearch
                                                    value={selectedZone[i]}
                                                    disabled={value === 2 || selectedBranch[i] ===
                                                        undefined}
                                                    onChange={(e) =>
                                                      handleSelectedZone(e, i)
                                                    }
                                                    allowClear
                                                    // ref={ref}

                                                    onClick={(e) =>
                                                      getCities(e, i)
                                                    }
                                                    // onPopupScroll={onScroll}
                                                    className="form-control paginated"
                                                    name="zone"
                                                >
                                                    {cities.map((city) => (
                                                        <Select.Option
                                                            key={city.id}
                                                            name="zone"
                                                            disabled={
                                                                value === 2
                                                            }
                                                            value={city.id}
                                                        >
                                                            {city.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select> */}
                                                    <label>{t("city")+ "*"}</label>

                                                    <SelectAreas
                                                        value={selectedArea[i]}
                                                        disabled={value === 2}
                                                        onChange={(e) =>
                                                            handleSelectedArea(
                                                                e,
                                                                i
                                                            )
                                                        }
                                                        allowClear
                                                        className="form-control paginated"
                                                        name="area"
                                                    />
                                                </div>
                                           
                                                <div className="text-area-form">
                                                    {/* <label>
                                                    {t("sideMenu.workModels")}
                                                </label>
                                                <Select
                                                    // id="new_work_model"
                                                    // title={t("sideMenu.workModels")}
                                                    // validation={{ required: true }}
                                                    // onScroll={(e) => onScroll(e, next)}
                                                    onChange={(e) =>
                                                        handleSelectedModel(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                    disabled={
                                                        selectedBranch[i] ==
                                                        undefined ||
                                                        workModels[i] ===
                                                        undefined
                                                    }
                                                    Value={selectedModel[i]}
                                                    allowClear
                                                    className="form-control paginated"
                                                    // backendErrors={
                                                    //     errors !== ""
                                                    //       ? errors.work_model?.length > 0
                                                    //         ? errors.work_model[0]
                                                    //         : null
                                                    //       : null
                                                    // }
                                                    name="new_work_model"
                                                >
                                                    {(workModels[i] ?? []).map(
                                                        (WorkModel) => {
                                                            return (
                                                                <Select.Option
                                                                    key={
                                                                        WorkModel.id
                                                                    }
                                                                    value={
                                                                        WorkModel.id
                                                                    }
                                                                    name="new_work_model"
                                                                >
                                                                    {
                                                                        WorkModel.name
                                                                    }
                                                                </Select.Option>
                                                            );
                                                        }
                                                    )}
                                                </Select> */}

                                                    <label className="label">
                                                        {t("address")+ "*"}
                                                    </label>

                                                    <Form.Control
                                                        className="formTextField text-area"
                                                        // title={"Weight"}
                                                        name="address"
                                                        onChange={(e) =>
                                                            handleInput(e, i)
                                                        }
                                                    />
                                                </div>
                                            
                                        
                                    </div>
                                );
                            })}
                        <p className="error">
                            {addressErr ? `${t("addressFields")}` : ""}
                        </p>
                        <div className="text-area-form mt-3">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={(e) =>
                                        setIsChecked(e.target.checked)
                                    }
                                />
                                <span className="ms-2">
                                    {"انا موافق علي كل "}
                                    <a 
                                        href="https://box.sa/en/-6" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "underline", color: "blue" }}
                                    >
                                        {"الشروط و الأحكام"}
                                    </a>
                                </span>
                            </label>
                            {checkboxError && (
                                <p className="error" style={{ color: "red" }}>
                                    {checkboxError}
                                </p>
                            )}
                        </div>
                    </fieldset>

                    <Row>
                        <Col className="text-end buttons-margin" sm="12">
                            <input
                                className="confirm"
                                value={t("register")}
                                type="submit"
                                
                            />
                        </Col>
                    </Row>
                </ModifiedForm>
                <CreationConfirmationModal
                    getErrors={getErrors}
                    setID={setID}
                    setPassword={setPassword}
                    title={lang === "ar" ? "انشاء حساب" : "ٌRegister"}
                    show={show}
                    showConfirm={showConfirmModal}
                    hide={hide}
                    account={account}
                    onBack={() => {}}
                />
                <AccountRegisterationSuccessModel
                    show={showConfirm}
                    hide={hideConfirm}
                    id={ID}
                    password={password}
                    account={account}
                />
                <UnSuccessfulRequest
                    show={uniqueNumberError}
                    title={
                        lang.includes("en")
                            ? "Phone Number isn't Unique"
                            : "رقم هاتف مستخدم من قبل"
                    }
                    body={
                        lang.includes("en")
                            ? "Please enter a unique phone number"
                            : "من فضلك ادخل رقم هاتف غير مستخدم من قبل"
                    }
                    hide={() => {
                        history.push("/register?step=1");
                    }}
                />

                <ToastContainer position="bottom-right" />
            </div>
        </div>
    );
};

export default RegisterInfo2;
